import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header.component';
import {IonicModule} from '@ionic/angular';
import {CookiesModule} from '../cookies/cookies.module';
import {BrandModule} from '../brand/brand.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    BrandModule,
    CookiesModule,
    RouterModule
  ]
})
export class HeaderModule { }
