import { Component, OnInit } from '@angular/core';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  public accepted: boolean;

  constructor(private alertController: AlertController) { }

  async ngOnInit() {
    const accepted = localStorage.getItem('accept-cookies');
    this.accepted = !! accepted;
  }

  accept() {
    localStorage.setItem('accept-cookies', 'true');
    this.accepted = true;
  }

}
