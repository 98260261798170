import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit {
  @Input() link = '/';
  @Input() theme: 'light' | 'dark' = 'dark';

  get classNames() {
    const classNames = ['brand', this.theme];
    return classNames.join(' ');
  }

  constructor() { }

  ngOnInit() {}

}
